<template>
    <div class="main">
        <div class="title">发布供给信息-服务机构</div>
        <el-form
            :model="formData"
            :rules="rules"
            ref="form"
            label-width="109px"
            label-position="right"
            size="small"
            style="max-width: 700px"
        >
            <el-form-item prop="name" label="机构名称">
                <el-input placeholder="请填写机构名称" style="width: 200px" v-model="formData.name"></el-input>
            </el-form-item>
            <el-form-item prop="field" label="擅长领域">
                <template>
                    <el-select
                        v-model="formData.field"
                        multiple
                        multiple-limit="3"
                        clearable
                        filterable
                        placeholder="请选择"
                        style="width: 400px"
                    >
                        <el-option
                            v-for="item in fieldOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </template>
            </el-form-item>
            <el-form-item prop="logo" label="机构logo">
                <single-upload v-model="formData.logo"> </single-upload>
            </el-form-item>
            <div class="description">请上传机构logo图片，格式JPG或PNG，尺寸500x500px，大小不超过1M</div>
            <el-form-item prop="introduction" label="机构简介" class="block">
                <el-input
                    type="textarea"
                    v-model="formData.introduction"
                    show-word-limit
                    maxlength="500"
                    :autosize="{ minRows: 6, maxRow: 6 }"
                    placeholder="请填写机构简介描述，500字以内"
                    resize="none"
                ></el-input>
            </el-form-item>
            <el-form-item prop="content" label="服务内容列表" class="block">
                <el-input
                    type="textarea"
                    v-model="formData.content"
                    style="width: 800px"
                    show-word-limit
                    maxlength="1500"
                    :autosize="{ minRows: 6, maxRow: 6 }"
                    placeholder="请填写机构简介描述，1500字以内"
                    resize="none"
                ></el-input>
            </el-form-item>
            <el-form-item prop="example" label="服务案例">
                <rich-text style="width: 800px" v-model="formData.example"></rich-text>
            </el-form-item>
            <el-form-item prop="contact" label="联系人">
                <el-input style="width: 200px" v-model="formData.contact"></el-input>
            </el-form-item>
            <el-form-item prop="phone" label="电话">
                <el-input style="width: 200px" v-model="formData.phone"></el-input>
            </el-form-item>
            <el-form-item prop="address" label="所在地区">
                <el-input style="width: 400px" placeholder="请输入所在地区" v-model="formData.address"></el-input>
            </el-form-item>
            <el-form-item prop="email" label="邮箱">
                <el-input style="width: 200px" v-model="formData.email"></el-input>
            </el-form-item>
        </el-form>
        <div class="btn">
            <el-button @click="onSave" :loading="saving" type="primary">确定</el-button>
            <el-button @click="onDelete" :disabled="saving" type="danger" v-if="formData.id"> 删除 </el-button>
            <el-button type="info" @click="$router.go(-1)" :disabled="saving">返回</el-button>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import { phonePattern } from '../../../utils/variables';
export default {
    name: 'ServiceProviderEdit',
    computed: {
        ...mapState(['userInfo'])
    },
    created() {
        if (this.$route.query.id) {
            this.$http
                .get('serviceProvider/get/' + this.$route.query.id)
                .then(res => {
                    if (!res.field) {
                        res.field = [];
                    }
                    this.formData = res;
                })
                .catch(e => {
                    console.log(e);
                    this.$message.error(e.error);
                });
        }
        this.$http
            .post('/setting/byFlag', { flag: 6 })
            .then(res => {
                if (res.length > 0) {
                    res.forEach(item => {
                        this.fieldOptions.push({
                            label: item.name,
                            value: item.id
                        });
                    });
                }
            })
            .catch(e => {
                console.log(e);
                this.$message.error(e.error);
            });
        this.formData = {
            contact: this.userInfo.nickname,
            phone: this.userInfo.phone,
            email: this.userInfo.email
        };
    },
    data() {
        return {
            saving: false,
            formData: {},
            rules: {
                name: [{ required: true, message: '请输入机构名称', trigger: 'blur' }],
                field: [{ required: true, message: '请选择擅长领域', trigger: 'blur' }],
                logo: [{ required: true, message: '请上传服务机构logo', trigger: 'blur' }],
                introduction: [{ required: true, message: '请输入机构简介', trigger: 'blur' }],
                content: [{ required: true, message: '请输入服务内容列表', trigger: 'blur' }],
                example: [{ required: true, message: '请输入服务案例', trigger: 'blur' }],
                contact: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
                phone: { required: true, pattern: phonePattern, message: '请输入联系电话', trigger: 'blur' },
                email: [{ type: 'email', required: true, message: '请输入邮箱', trigger: 'blur' }],
                address: [{ required: true, message: '请输入所在地区', trigger: 'blur' }]
            },
            needChange: true,
            fieldOptions: []
        };
    },
    methods: {
        onSave() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.submit();
                    setTimeout(() => {
                        this.$confirm('发布成功，正在等待平台审核', '提示', {
                            confirmButtonText: '继续发布',
                            cancelButtonText: '退出',
                            type: 'warning'
                        }).then(() => {
                            setTimeout(() => {
                                this.$router.push('/serviceProviderEdit');
                                this.formData = '';
                                this.formData = {
                                    contact: this.userInfo.nickname,
                                    negotiateDirectly: false,
                                    phone: this.userInfo.phone,
                                    email: this.userInfo.email
                                };
                            }, 1000);
                        });
                        // .catch(() => {
                        //     this.show = false;
                        // });
                    }, 1000);
                } else {
                    return false;
                }
            });
        },
        submit() {
            let data = { ...this.formData };
            this.saving = true;
            this.$http
                .post('/serviceProvider/save', data, { body: 'json' })
                .then(() => {
                    this.saving = false;
                    this.$message.success('成功');
                    this.$router.go(-1);
                })
                .catch(e => {
                    console.log(e);
                    this.saving = false;
                    this.$message.error(e.error);
                });
        },
        onDelete() {
            this.$alert('删除将无法恢复，确认要删除么？', '警告', { type: 'error' })
                .then(() => {
                    return this.$http.post(`/serviceProvider/del/${this.formData.id}`);
                })
                .then(() => {
                    this.$message.success('删除成功');
                    this.$router.go(-1);
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        console.log(e);
                        this.$message.error((e || {}).error || '删除失败');
                    }
                });
        }
    }
};
</script>
<style lang="less" scoped>
/deep/ .el-button {
    width: 100px;
    border-radius: 4px;
    margin-left: 25px;
}
/deep/ .el-form {
    margin-top: 30px;
}
.main {
    background-color: #fff;
    margin: 17px 20px 0;
    padding-bottom: 60px;
    .btn {
        text-align: right;
    }
    .description {
        font-size: 14px;
        font-weight: 400;
        color: #c8c9cc;
        line-height: 24px;
        margin: -38px 0 20px 108px;
    }
    .title {
        font-size: 16px;
        font-weight: bold;
        color: #000000;
        padding-bottom: 17px;
        line-height: 26px;
        border-bottom: 1px solid @bg;
    }
    .block {
        width: 400px;
        height: 100px;
        padding-bottom: 30px;
    }
}
</style>
